export const ROUTES = {
  HOME: '/',
  NFT: {
    VIEW: (chain: string, contract: string, tokenId: string) =>
      `/${chain}/${contract}/${tokenId}`,
  },
  CCO: '/CCO',
  WALLETS: '/wallets',
  SEARCH: '/search',
  CHECKOUT: {
    ROOT: '/checkout',
    WITH_SESSION: (clientSecret: string) =>
      `/checkout?client_secret=${clientSecret}`,
    RETURN: '/return',
    CONFIRMATION: '/confirmation',
  },
  SETTINGS: {
    ROOT: '/settings',
    ORDERS: '/settings/orders',
    LINKED_WALLETS: '/settings/linked-wallets',
    PROFILE: '/settings/profile',
  },
  SUPPORT: {
    CUSTOMER_SERVICE: '/customer-service',
    FAQ: '/faq',
    SHIPPING: '/shipping',
    POINTS: '/points',
    PRIVACY: '/privacy',
    TERMS: '/terms',
  },
} as const;

export const EXTERNAL_LINKS = {
  FARCASTER_CHANNEL: 'https://warpcast.com/~/channel/outpaint',
} as const;
