'use client';

import { http } from 'viem';
import { arbitrum, base, mainnet, optimism, polygon } from 'viem/chains';

import { PrivyClientConfig, PrivyProvider } from '@privy-io/react-auth';
import { createConfig, WagmiProvider } from '@privy-io/wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const supportedChains = [
  mainnet, // l1
  base, // op stack
  optimism, // op stack
  arbitrum, // arbitrum (different rollup tech)
  polygon, // polygon pos (different scaling solution)
];

const transports = {
  [mainnet.id]: http(),
  [base.id]: http(),
  [optimism.id]: http(),
  [arbitrum.id]: http(),
  [polygon.id]: http(),
};

export const wagmiConfig = createConfig({
  chains: supportedChains,
  transports,
});

const privyConfig: PrivyClientConfig = {
  loginMethods: ['farcaster', 'email'],
  defaultChain: base,
  supportedChains: supportedChains,
  appearance: {
    theme: 'light',
    accentColor: '#FF3B00',
    landingHeader: '',
    showWalletLoginFirst: false,
  },
  embeddedWallets: {
    createOnLogin: 'users-without-wallets',
  },
};

export function CSPrivyProvider({ children }: { children: React.ReactNode }) {
  return (
    <PrivyProvider
      appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID || ''}
      config={privyConfig}
    >
      <QueryClientProvider client={queryClient}>
        <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>
      </QueryClientProvider>
    </PrivyProvider>
  );
}
