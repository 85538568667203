import { Link } from 'next-view-transitions';
import { usePathname } from 'next/navigation';

interface NavLinkProps {
  href: string;
  children: React.ReactNode;
  openInNewTab?: boolean;
  className?: string;
}

export function NavLink({
  href,
  children,
  openInNewTab = false,
  className = '',
}: NavLinkProps) {
  const linkProps = openInNewTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {};
  const pathname = usePathname();
  const isActive = (path: string) => pathname === path;
  const active = isActive(href);
  return (
    <Link href={href} className="rounded-full" {...linkProps}>
      <div
        className={`rounded-full px-2 hover:bg-black/10 dark:hover:bg-white/10 ${
          active ? 'bg-black dark:bg-white' : ''
        } ${className}`}
      >
        <p
          className={`font-display py-[3px] text-sm tracking-wide ${
            active
              ? 'text-white dark:text-black'
              : 'text-black hover:text-gray-900 dark:text-white dark:hover:text-gray-100'
          }`}
        >
          {children}
        </p>
      </div>
    </Link>
  );
}
