'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';
import { useAccount, useEnsName } from 'wagmi';

import { usePrivy } from '@privy-io/react-auth';

if (
  typeof window !== 'undefined' &&
  window.location.hostname === process.env.NEXT_PUBLIC_OUTPAINT_HOST!
) {
  /** reverse proxy via redirects: https://posthog.com/docs/advanced/proxy/nextjs */
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: '/ingest',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    capture_pageview: false, //  avoid the initial pageview from being duplicated
    debug: process.env.NODE_ENV === 'development',
  });
}

export function CSPostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider client={posthog}>
      <PostHogAuthWrapper>{children}</PostHogAuthWrapper>
    </PostHogProvider>
  );
}

function PostHogAuthWrapper({ children }: { children: React.ReactNode }) {
  const { address } = useAccount();
  const { ready, user, authenticated } = usePrivy();
  const { data: ensName } = useEnsName({ address });

  /** regardless ensName is not ready  */
  /** identify user in support as a visitor or post sign in as a customer to their privyId  */
  /** can call atlas.call multiple times post login when user adds email at a later experience */
  useEffect(() => {
    const initAtlas = () => {
      if (ready && authenticated && window.Atlas) {
        const { id, createdAt, ...rest } = user ?? {};
        posthog.identify(id, {
          createdAt: createdAt,
          ensName: ensName ?? undefined,
          ...rest,
        });
        window.Atlas.call('identify', {
          userId: id,
        });
      } else if (!authenticated) {
        posthog.reset();
      }
    };

    if (window.Atlas) {
      initAtlas();
    } else {
      window.addEventListener('AtlasLoaded', initAtlas);
      return () => window.removeEventListener('AtlasLoaded', initAtlas);
    }
  }, [authenticated, ensName, ready, user]);

  return children;
}
